import { AnimationEvent } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterContentInit,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { matExpansionAnimations } from '@angular/material/expansion';
import { dsAnimations } from '@design-system/cdk/animations';
import { dsConfig } from '@design-system/cdk/config';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DsDrawerActions } from './store/drawer.actions';
import { DsDrawerState } from './store/drawer.reducer';
import { DsDrawerSelectors } from './store/drawer.selectors';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'ds-drawer-content',
  template: '<ng-content></ng-content>',
  animations: [dsAnimations.stepTransition],
  standalone: false,
})
export class DsDrawerContentComponent implements AfterContentInit, OnDestroy {
  @Input() routerStepState: 'previous' | 'next' | 'current' = 'current';

  animationDone = new Subject<AnimationEvent>();

  private _destroy$ = new Subject<void>();

  @HostBinding('@dsStepTransition') get stepTransition() {
    return this.routerStepState;
  }
  @HostListener('@dsStepTransition.done', ['$event'])
  stepTransitionDoneListener(event: AnimationEvent) {
    this.animationDone.next(event);
  }

  ngAfterContentInit() {
    this.animationDone
      .pipe(
        // This needs a `distinctUntilChanged` in order to avoid emitting the same event twice due
        // to a bug in animations where the `.done` callback gets invoked twice on some browsers.
        // See https://github.com/angular/angular/issues/24084
        distinctUntilChanged(
          (x, y) => x.fromState === y.fromState && x.toState === y.toState,
        ),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this.routerStepState = 'current';
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ds-drawer-sidebar',
  standalone: false,
})
export class DsDrawerSidebarDirective {}

@Component({
  selector: 'ds-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  animations: [
    dsAnimations.fadeIn,
    dsAnimations.fadeOut,
    matExpansionAnimations.indicatorRotate,
  ],
  standalone: false,
})
export class DsDrawerComponent implements OnDestroy {
  @Input() isSidebarDisabled = false;
  @Input() isInStepper = false;
  @Input() isContainingRouter = false;

  @Input() set stickyOffset(value: number) {
    this._store.dispatch(DsDrawerActions.SetStickyOffset({ offset: value }));
  }

  @Input() set isSidebarHidden(value: boolean) {
    this.setSidebarHidden(value);
  }
  get isSidebarHidden() {
    return this._isSidebarHidden;
  }

  @Input() set isExpanded(value: boolean) {
    this.setExpanded(value);
  }
  get isExpanded() {
    return this._isExpanded;
  }

  @ContentChild(MatStepper) set stepper(value: MatStepper) {
    if (value) {
      this.isContainingStepper = true;
    }
  }

  @ViewChild('sidebarWrapper') set sidebarWrapperElement(value: ElementRef) {
    this._sidebarWrapperElement = value;
    setTimeout(() => this.setSidebarMaxHeight(), 0);
  }

  isContainingStepper = false;
  isMobile = false;
  sidebarMaxHeight = 'auto';
  stickyOffset$: Observable<number>;

  private _isExpanded = true;
  private _isSidebarHidden = true;
  private _sidebarWrapperElement: ElementRef;
  private _destroy$ = new Subject<void>();

  @HostListener('window:resize', ['$event']) onResize() {
    this.setSidebarMaxHeight();
  }

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _store: Store<DsDrawerState>,
  ) {
    this._breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(takeUntil(this._destroy$))
      .subscribe((bpState) => {
        this.isMobile = bpState.matches;
        this.setExpanded(!bpState.matches);
      });

    this._store
      .select(DsDrawerSelectors.isSidebarExpanded)
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this._isExpanded = value;
      });

    this._store
      .select(DsDrawerSelectors.isSidebarHidden)
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this._isSidebarHidden = value;
      });

    this.stickyOffset$ = this._store.select(DsDrawerSelectors.stickyOffset);
  }

  setExpanded(value: boolean) {
    this._store.dispatch(
      DsDrawerActions.SetSidebarExpanded({ isExpanded: value }),
    );
  }

  setSidebarHidden(value: boolean) {
    this._store.dispatch(DsDrawerActions.SetSidebarHidden({ isHidden: value }));
  }

  setSidebarMaxHeight() {
    const rect =
      this._sidebarWrapperElement?.nativeElement?.getBoundingClientRect();
    if (rect) {
      this.sidebarMaxHeight =
        'calc(100vh - ' +
        (rect.top +
          3 * dsConfig.spacing +
          (this.isContainingRouter || this.isContainingStepper
            ? 4 * dsConfig.spacing
            : 0)) +
        'px)';
    } else {
      this.sidebarMaxHeight = 'auto';
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
